import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Empty } from '@h3/antd-vue';
import { AppAuthService } from '@/sevices';
import { Loading } from '@cloudpivot-hermes/common-components';
import Perm from '@/router/permission';
let AppInstalled = class AppInstalled extends Vue {
    constructor() {
        super(...arguments);
        this.installedAppList = [];
        this.pageLoading = false;
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        this.keyword = '';
    }
    get hasDetailPerm() {
        return Perm.hasCodePerm(this.$PermCode.appDetail.appDetail);
    }
    onKeywordChange() {
        this.keyword = this.keyword.replace(/^\s*|\s*$/g, '');
    }
    /**
     * 搜索
     */
    search(searchKey) {
        const params = {
            keyword: searchKey,
            page: 0,
            size: 100000000,
        };
        this.getInstalledList(params);
    }
    /**
     * 获取列表数据接口
     */
    async getInstalledList(params) {
        this.pageLoading = true;
        const res = await AppAuthService.getAppList(params);
        const data = res.data || [];
        if (res.success) {
            this.installedAppList = data;
        }
        this.pageLoading = false;
    }
    goDetail(item) {
        this.$router.push({
            name: 'app-detail',
            params: {
                appCode: item.appCode,
                appVersion: item.version,
            },
            query: {
                appName: item.appName,
            },
        });
    }
    created() {
        const params = {
            page: 0,
            size: 100000000,
        };
        this.getInstalledList(params);
    }
};
__decorate([
    Watch('keyword')
], AppInstalled.prototype, "onKeywordChange", null);
AppInstalled = __decorate([
    Component({
        name: 'AppInstalled',
        components: {
            AppImage: () => import('@/components/global/app-image'),
            Loading,
        },
    })
], AppInstalled);
export default AppInstalled;
